import React from 'react'
import Layout from '../components/Layout'

const NotFoundPage = () => (
  <Layout>
    <p>Best.Coffee.Ever.</p>
  </Layout>
)

export default NotFoundPage
